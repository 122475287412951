import styled from '@emotion/styled'
import { Grid, IconButton, Typography, Button } from '@material-ui/core'
import { Form } from 'formik'
import { Input, SubmitButton } from 'formik-antd'
import { motion } from 'framer-motion'
import { cssVariables } from 'theme'

export const ModalContainer = styled.div`
  // display: flex;
  // width: 100%;
  // height: 100%;
  // padding: 0rem;
  // flex-direction: row;
`
export const ModalInput = styled(motion.div)`
  display: flex;
  height: 100%;
  padding: 0rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 1024px) {
    padding: 1rem;
  }
  @media (max-width: 768px) {
    padding: 0rem;
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`

export const SignInForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  max-width: 600px;
`
export const SignInInput = styled.div`
  min-width: 75%;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export const SignInHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 44px;
  align-content: center;
  row-gap: 16px;
`
export const Header = styled.div`
  margin-left: 2rem;
  @media (max-width: 600px) {
    position: fixed;
    width: 100%;
    top: 0;
    padding: 10px;
  }
`
export const Title = styled(Typography)`
  font-size: 60px;
  font-weight: 900;
  line-height: 65.4px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
export const SubTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
export const FormStyled = styled(Form)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`
export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
`
export const ErrorField = styled.p`
  color: red;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

export const InfoText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

`
const InputDefaultStyles = `
width: 100%;
border-radius: 6px;
height: 4.4rem;
`
export const UsernameInput = styled(Input)`
  ${InputDefaultStyles}
  margin-bottom: 1rem;
`
export const PasswordInput = styled(Input)`
  ${InputDefaultStyles}
`

export const InputStyled = styled(Input)`
  ${InputDefaultStyles}
`
export const SubmitButtonStyled = styled(SubmitButton)`
  background-color: ${cssVariables.colors.primaryBlue};
  hover: ${cssVariables.colors.primaryBlue};
  height: 4rem;
  border-radius: 6px;
  padding: 8px 16px;
  margin: 36px 0 17px 0;
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover: {
    background-color: ${cssVariables.colors.primaryDarkGrey};
  }
`
export const SignUpFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 17px;
  margin-top: 17px;
`
export const SignUpFooterLinks = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.012em;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 17px;

`
export const SignUpButtonLink = styled.button`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: none;
  color: #0545BA;
  width: 100%;
  display: flex;
  justfiy-content: flex-start;
  padding: 0;
  text-decoration: underline;
`
export const RightSideImageContainer = styled.div`
  position: relative;
  width: 100%;
`
export const RightSideImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 0rem;
  object-fit: cover;
`
export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${cssVariables.colors.primaryWhite};
  background-color: ${cssVariables.colors.primaryBlue};
  &:hover: {
    background-color: ${cssVariables.colors.primaryDarkGrey};
  }
`
