import React, { useEffect } from 'react'

import { Grid, LinearProgress } from '@material-ui/core'

import { CONSTANTS } from 'core/constants'
import { Formik } from 'formik'
import { HTTPError } from 'ky'
import { TermsOfService } from 'layouts/Auth/components/TermsOfService'
import {  isEmpty } from 'lodash'
import { sendNotification } from 'utils/toast'
import {  useAppSelector } from 'store/hooks'
import { resetPassword } from 'api/auth'

import {
  SignInForm,
  SignInInput,
  SignInHeader,
  Title,
  SubTitle,
  FormStyled,
  Label,
  SignUpFooter,
  SignUpFooterLinks,
  SignUpButtonLink,
  InputStyled,
  SubmitButtonStyled,
} from '../AuthStyles'
import { pwResetValidationSchema } from './helper'
import { useHistory } from 'react-router'

type Props = {
  onCloseModal: (val: boolean) => void
}

type ResetPasswordObject = {
  email: string
}

const ForgotPassword = () => {
  const { user } = useAppSelector(state => state.user)

  const history = useHistory()
  /**
   * Send user reset password email.
   * @param { email } param
   */
  const onSendResetPasswordEmail = async ({ email }: ResetPasswordObject) => {
    try {
      const res = await resetPassword({ email })
      if (res.success) {
        sendNotification(
          CONSTANTS.PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE,
          'success',
        )
        history.push('signin')
      }
    } catch (error) {
      const res = await (error as HTTPError)?.response?.json()
      const errorMsg = !isEmpty(res) ? res.message : CONSTANTS.ERROR_GENERAL
      sendNotification(errorMsg, 'error')
    }
  }

  useEffect(() => {
    // Handle redirection if user is already logged in
    if (user.username) {
     history.push('/')
    }
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
      <SignInForm style={{ width: '95%', height: 'fit-content'}}>
          <SignInInput>
            <SignInHeader>
              <Title>{CONSTANTS.RESET_PASSWORD}</Title>
              <SubTitle>{CONSTANTS.SIGN_IN_HEADER_SUBTITLE}</SubTitle>
            </SignInHeader>
            <Formik
              initialValues={{
                email: '',
              }}
              validateOnBlur={false}
              validationSchema={pwResetValidationSchema}
              onSubmit={async ({ email }) => {
                await onSendResetPasswordEmail({ email })
              }}>
              {({ isSubmitting }) => (
                <FormStyled>
                  <Label>Email</Label>
                  <InputStyled name="email" type="email" autoFocus />
                  {isSubmitting && <LinearProgress />}
                  <SubmitButtonStyled color="primary">
                    {CONSTANTS.RESET_PASSWORD}
                  </SubmitButtonStyled>
                </FormStyled>
              )}
            </Formik>
            <SignUpFooter>
              <SignUpFooterLinks>
                  <SignUpButtonLink onClick={() => history.push('/signin')}>
                    {CONSTANTS.ALREADY_HAVE_ACCOUNT_SIGN_IN}
                  </SignUpButtonLink>
              </SignUpFooterLinks>
            </SignUpFooter>
          </SignInInput>
          <TermsOfService />
      </SignInForm>
      </div>
  )
}

export default ForgotPassword
